@import "./app/web-components/styles/index";

.snackbar-success {
  --mdc-snackbar-supporting-text-color: #f5eff7;
  --mat-snack-bar-button-color: #d0bcff;
}

.snackbar-error {
  --mdc-snackbar-container-color: #a93c3c;
  --mdc-snackbar-supporting-text-color: #f5eff7;
  --mat-snack-bar-button-color: #f5eff7;
}

html body .cdk-overlay-container {
  z-index: 99999;
  --md-sys-typescale-body-large-size: 14px;
  --md-sys-typescale-label-large-size: 14px;
}

.mat-calendar {
  .mat-calendar-controls {
    button {
      &.mat-calendar-previous-button,
      &.mat-calendar-next-button {
        margin: 0;
        min-height: unset;
        &::after {
          display: block;
        }
      }
    }
  }

  .mat-calendar-table {
    .mat-calendar-table-header {
      tr th {
        background-color: unset;
      }
    }

    .mat-calendar-body {
      tr td {
        button.mat-calendar-body-cell {
          font-weight: 400;

          &:hover {
            background-color: unset;
          }
          &:focus {
            outline: unset;
            outline-color: unset;
          }
        }
      }
    }
  }
}
