/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

@import '@angular/cdk/overlay-prebuilt.css';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

:root {
  --md-ref-typeface-brand: 'Roboto';
  --md-ref-typeface-plain: 'Roboto';
  --md-elevation-level: 0;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@for $i from 0 through 24 {
  .mat-mdc-card.mat-elevation-z#{$i} {
    @include mat.elevation($i);
  }
}
